import { Injectable } from '@angular/core';

import { ServiceAccount } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceAccountsService extends GenericObjectsService<ServiceAccount> {
  protected baseUrl = '/service-accounts';
}
